import React, { ReactNode, useEffect, useState } from 'react';
import styles from './Layout.module.scss';
import Sidebar from '../Sidebar/Sidebar';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { routes } from '../../config/Router/routes';
import {
  faBuilding,
  faFileExport,
  faFileSignature,
  faGlobe,
  faListCheck,
  faTag,
  faUser,
  faUserPen,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import Navigation from '../Navigation/Navigation';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { Company } from '../../domain/Company';
import Modal from '../Modal/Modal';
import CompanySelectForm from '../../components/CompanySelectForm/CompanySelectForm';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as companyService from '../../store/company/service';
import CurrentAccount from '../CurrentAccount/CurrentAccount';
import { connect } from 'react-redux';
import { Roles } from '../../domain/User';
import { Locale } from '../../domain/Translation';
import { getLocaleUrl } from '../../utility/url/urlHelper';
import { setSelectedCompany } from '../../store/company/actions';

export type Props = {
  children: ReactNode;
  isAuthenticated: boolean;
  companyOptions: Company[];
  onCompanyOptionsFetch: () => void;
  selectedCompany: number | null;
  selectedLocale: Locale;
  onCompanySelect: (selectCompanyId: number) => void;
  selectedRole: Roles | null;
};

export type NavigationItem = {
  label: string;
  to: string;
  icon: IconProp;
  roles: Roles[];
};

export type NavigationGroup = {
  label: string;
  items: NavigationItem[];
};

const MOBILE_BREAK_POINT = 900;

const Layout = ({
  children,
  isAuthenticated,
  companyOptions,
  onCompanyOptionsFetch,
  selectedCompany,
  selectedLocale,
  onCompanySelect,
  selectedRole,
}: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [company, setCompany] = useState<Company | undefined>(undefined);

  const { width } = useWindowSize();
  const routeLocation = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [routeLocation.key]);

  const SIDEBAR_ITEMS = [
    {
      label: translate(
        intl,
        selectedRole === Roles.ADMIN
          ? 'SIDEBAR.CATEGORY_ADMIN'
          : 'SIDEBAR.CATEGORY_EMPLOYEE',
      ),
      roles: [Roles.ADMIN, Roles.USER],
      items: [
        {
          label: translate(intl, 'SIDEBAR.MY_TASKS'),
          to: routes.tasks.list,
          icon: faListCheck,
          roles: [Roles.USER],
        },
        {
          label: translate(intl, 'SIDEBAR.MY_DOCUMENTS'),
          to: routes.myDocuments.list,
          icon: faFileSignature,
          roles: [Roles.USER],
        },
        {
          label: translate(intl, 'SIDEBAR.COMPANIES'),
          to: routes.companies.list,
          icon: faBuilding,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'SIDEBAR.USERS'),
          to: routes.users.list,
          icon: faUser,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_TRANSLATIONS'),
          to: routes.translations,
          icon: faGlobe as IconProp,
          roles: [Roles.ADMIN],
        },
      ],
    },
  ];

  useEffect(() => {
    if (isMobileMenuOpen) {
      window.scroll({ top: 0 });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobileMenuOpen]);

  useEffect(() => {
    if (isAuthenticated && selectedRole !== Roles.USER) {
      onCompanyOptionsFetch();
    }
  }, []);

  useEffect(() => {
    if (
      !selectedCompany &&
      companyOptions.length &&
      (selectedRole === Roles.OWNER || selectedRole === Roles.MANAGER)
    ) {
      onCompanySelect(Number(companyOptions[0].id));
      navigate(getLocaleUrl(routes.dashboard, selectedLocale));
      setTimeout(() => {
        navigate(getLocaleUrl(routes.mainTasks.list, selectedLocale));
      }, 50);
    }

    if (selectedCompany && companyOptions.length === 0) {
      return;
    }

    setCompany(
      companyOptions.find(
        (companyOption) => companyOption.id === selectedCompany,
      ),
    );
  }, [companyOptions, selectedCompany]);

  useEffect(() => {
    if (selectedCompany) {
      setIsModalOpen(false);
    }
  }, [selectedCompany]);

  const getNavigationGroups = () => {
    const sidebarItems = SIDEBAR_ITEMS.filter(
      (item) => selectedRole && item.roles.includes(selectedRole),
    );

    if (!company) {
      return sidebarItems;
    }

    return [
      {
        label: translate(intl, 'SIDEBAR.COMPANY_SETTINGS'),
        roles: [Roles.ADMIN, Roles.OWNER, Roles.MANAGER],
        items: [
          {
            label: translate(intl, 'SIDEBAR.TASKS'),
            to: routes.mainTasks.list,
            icon: faListCheck,
            roles: [Roles.MANAGER, Roles.OWNER, Roles.ADMIN],
          },
          {
            label: translate(intl, 'SIDEBAR.DOCUMENTS'),
            to: routes.documents.list,
            icon: faFileSignature,
            roles: [Roles.MANAGER, Roles.OWNER, Roles.ADMIN],
          },
          {
            label: translate(intl, 'SIDEBAR.JOB_TITLES'),
            to: routes.jobTitles.list,
            icon: faTag,
            roles: [Roles.MANAGER, Roles.OWNER, Roles.ADMIN],
          },
          {
            label: translate(intl, 'SIDEBAR.SIGNEES'),
            to: routes.signees.list,
            icon: faUserPen,
            roles: [Roles.MANAGER, Roles.OWNER, Roles.ADMIN],
          },
          {
            label: translate(intl, 'SIDEBAR.EXPORT'),
            to: routes.export,
            icon: faFileExport,
            roles: [Roles.ADMIN, Roles.OWNER, Roles.MANAGER],
          },
        ],
      },
      ...sidebarItems,
    ];
  };

  const onCompanySelectCallback = () => {
    setIsModalOpen(false);

    if (
      routeLocation.pathname === getLocaleUrl(routes.dashboard, selectedLocale)
    ) {
      navigate(getLocaleUrl(routes.mainTasks.list, selectedLocale));
    }
  };

  const getCurrentCompany = () => {
    if (
      selectedRole &&
      (selectedRole === Roles.USER || selectedRole === Roles.MANAGER)
    ) {
      return null;
    }

    return (
      <CurrentAccount
        title={translate(intl, 'COMPANIES.NO_COMPANY_SELECTED')}
        subTitle={
          company
            ? `${translate(intl, 'COMPANIES.SELECTED_COMPANY')}:`
            : translate(intl, 'COMPANIES.CLICK_TO_SELECT')
        }
        companyName={company?.name}
        onClick={() => !isModalOpen && setIsModalOpen(true)}
      >
        <Modal
          onClose={() => setIsModalOpen(false)}
          isOpen={isModalOpen}
          title={translate(intl, 'COMPANIES.SELECT_COMPANY_TITLE')}
          className={styles.companySelectModal}
        >
          {isModalOpen && (
            <CompanySelectForm onSuccessSelect={onCompanySelectCallback} />
          )}
        </Modal>
      </CurrentAccount>
    );
  };

  if (
    !routeLocation.pathname.includes(
      getLocaleUrl(routes.dashboard, selectedLocale),
    )
  ) {
    return <>{children}</>;
  }

  if (!isAuthenticated) {
    return <>{children}</>;
  }

  return (
    <>
      <div className={styles.container}>
        {width && width >= MOBILE_BREAK_POINT && (
          <Sidebar
            navigationGroups={getNavigationGroups()}
            currentAccount={getCurrentCompany()}
            selectedRole={selectedRole}
            selectedLocale={selectedLocale}
          />
        )}
        <div
          className={cx(styles.rightSection, {
            [styles.noScroll]: isMobileMenuOpen,
          })}
        >
          <Navigation
            onDrawerClick={() => setIsMobileMenuOpen((prev) => !prev)}
            isMobileMenuOpen={isMobileMenuOpen}
          />
          <div className={styles.content}>{children}</div>
        </div>
      </div>
      {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
        <div className={styles.mobileDrawer}>
          {getCurrentCompany()}
          {getNavigationGroups().map((navigationGroup) => (
            <div className={styles.navigationGroup} key={navigationGroup.label}>
              <div className={styles.groupName}>{navigationGroup.label}</div>
              {navigationGroup.items
                .filter(
                  (navItem) =>
                    selectedRole && navItem.roles.includes(selectedRole),
                )
                .map((item) => (
                  <NavLink
                    key={item.label}
                    to={getLocaleUrl(item.to, selectedLocale)}
                    className={({ isActive }) =>
                      cx(styles.navigationItem, {
                        [styles.activeSubItem]: isActive,
                      })
                    }
                  >
                    {item.label}
                  </NavLink>
                ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedCompany: state.company.selectedCompany,
  companyOptions: state.company.companyOptions,
  selectedLocale: state.auth.selectedLocale,
  selectedRole: state.auth.role,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onCompanyOptionsFetch: () => dispatch(companyService.fetchCompanyOptions()),
  onCompanySelect: (selectCompanyId: number) =>
    dispatch(setSelectedCompany(selectCompanyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
