export const routes = {
  dashboard: '/:locale/dashboard',
  documents: {
    list: '/:locale/dashboard/documents',
    edit: '/:locale/dashboard/documents/:id',
    create: '/:locale/dashboard/documents/create',
    details: '/:locale/dashboard/documents/:id/details',
  },
  signees: {
    list: '/:locale/dashboard/signees',
    edit: '/:locale/dashboard/signees/:id',
    create: '/:locale/dashboard/signees/create',
  },
  companies: {
    list: '/:locale/dashboard/companies',
    edit: '/:locale/dashboard/companies/:id',
    create: '/:locale/dashboard/companies/create',
  },
  users: {
    list: '/:locale/dashboard/users',
  },
  jobTitles: {
    list: '/:locale/dashboard/job-titles',
    edit: '/:locale/dashboard/job-titles/:id',
    create: '/:locale/dashboard/job-titles/create',
  },
  mainTasks: {
    list: '/:locale/dashboard/main-tasks',
    create: '/:locale/dashboard/main-tasks/create',
    details: '/:locale/dashboard/main-tasks/:id',
  },
  tasks: {
    list: '/:locale/dashboard/tasks',
    details: '/:locale/dashboard/tasks/:id',
  },
  myDocuments: {
    list: '/:locale/dashboard/my-documents',
  },
  login: '/:locale/login',
  passwordRemind: '/:locale/password-remind',
  passwordReset: '/:locale/password-reset/:code',
  register: '/:locale/register/:code',
  translations: '/:locale/dashboard/translations',
  export: '/:locale/dashboard/export',
};
