import * as actionTypes from './actionTypes';
import { DocumentActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Document } from '../../domain/Document';

export type DocumentStateType = {
  documentsListUpdateNeeded: boolean;
  documentCreateLoading: boolean;
  documentCreateError: HttpError;
  documentCreateSuccess: boolean;
  createdDocument: Document | null;
  document: Document | null;
  documentLoading: boolean;
  documentError: HttpError;
  documentUpdateLoading: boolean;
  documentUpdateError: HttpError;
  documentUpdateSuccess: boolean;
};

export type DocumentActionType = DocumentStateType & {
  type: DocumentActionTypes;
};

export const initialState: DocumentStateType = {
  documentsListUpdateNeeded: false,
  documentCreateLoading: false,
  documentCreateError: null,
  documentCreateSuccess: false,
  createdDocument: null,
  document: null,
  documentLoading: false,
  documentError: null,
  documentUpdateLoading: false,
  documentUpdateError: null,
  documentUpdateSuccess: false,
};

const fetchDocumentStart = (state: DocumentStateType): DocumentStateType => ({
  ...state,
  documentLoading: true,
  documentCreateSuccess: false,
  documentCreateError: null,
});

const fetchDocumentSuccess = (
  state: DocumentStateType,
  action: DocumentActionType,
): DocumentStateType => ({
  ...state,
  document: action.document,
  documentLoading: false,
  documentError: null,
});

const fetchDocumentFail = (
  state: DocumentStateType,
  action: DocumentActionType,
): DocumentStateType => ({
  ...state,
  documentError: action.documentError,
  documentLoading: false,
});

const createDocumentStart = (state: DocumentStateType): DocumentStateType => ({
  ...state,
  documentCreateLoading: true,
});

const createDocumentSuccess = (
  state: DocumentStateType,
  action: DocumentActionType,
): DocumentStateType => ({
  ...state,
  documentCreateLoading: false,
  documentCreateError: null,
  documentCreateSuccess: true,
  createdDocument: action.createdDocument,
});

const createDocumentFail = (
  state: DocumentStateType,
  action: DocumentActionType,
): DocumentStateType => ({
  ...state,
  documentCreateLoading: false,
  documentCreateError: action.documentCreateError,
});

const updateDocumentStart = (state: DocumentStateType): DocumentStateType => ({
  ...state,
  documentUpdateLoading: true,
});

const updateDocumentSuccess = (
  state: DocumentStateType,
): DocumentStateType => ({
  ...state,
  documentUpdateLoading: false,
  documentUpdateError: null,
  documentUpdateSuccess: true,
});

const updateDocumentFail = (
  state: DocumentStateType,
  action: DocumentActionType,
): DocumentStateType => ({
  ...state,
  documentUpdateLoading: false,
  documentUpdateError: action.documentUpdateError,
});

const resetDocumentCreateUpdateState = (
  state: DocumentStateType,
): DocumentStateType => ({
  ...state,
  documentCreateSuccess: false,
  documentUpdateSuccess: false,
});

const resetDocumentStore = (): DocumentStateType => ({
  documentsListUpdateNeeded: false,
  documentCreateLoading: false,
  documentCreateError: null,
  documentCreateSuccess: false,
  createdDocument: null,
  document: null,
  documentLoading: false,
  documentError: null,
  documentUpdateLoading: false,
  documentUpdateError: null,
  documentUpdateSuccess: false,
});

const reducer = (state = initialState, action: DocumentActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_DOCUMENT_START:
      return fetchDocumentStart(state);
    case actionTypes.FETCH_DOCUMENT_SUCCESS:
      return fetchDocumentSuccess(state, action);
    case actionTypes.FETCH_DOCUMENT_FAIL:
      return fetchDocumentFail(state, action);
    case actionTypes.CREATE_DOCUMENT_START:
      return createDocumentStart(state);
    case actionTypes.CREATE_DOCUMENT_SUCCESS:
      return createDocumentSuccess(state, action);
    case actionTypes.CREATE_DOCUMENT_FAIL:
      return createDocumentFail(state, action);
    case actionTypes.RESET_DOCUMENT_CREATE_STATE:
      return resetDocumentCreateUpdateState(state);
    case actionTypes.UPDATE_DOCUMENT_START:
      return updateDocumentStart(state);
    case actionTypes.UPDATE_DOCUMENT_SUCCESS:
      return updateDocumentSuccess(state);
    case actionTypes.UPDATE_DOCUMENT_FAIL:
      return updateDocumentFail(state, action);
    case actionTypes.RESET_DOCUMENT_STORE:
      return resetDocumentStore();
    default:
      return state;
  }
};

export default reducer;
