import { ChangeEvent, ReactNode, useState } from 'react';
import {
  getValidationError,
  ValidationRule,
} from '../../utility/validation/validation';
import _, { trim } from 'lodash';
import { HttpError } from '../../config/Axios/axios-instance';
import { useIntl } from 'react-intl';
import { Asset } from '../../domain/Asset';

const IGNORED_TRIM = ['password', 'file', 'autocomplete', 'custom', 'upload'];

export type LabelPlacement = 'end' | 'start' | 'top' | 'bottom';

export type FormInputBlueprint = {
  name: string;
  label?: ReactNode;
  type: string;
  validation?: Array<ValidationRule>;
  value?: string | string[] | number | File | null;
  isHidden?: boolean;
  options?: Array<any>;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
  capitalize?: boolean;
  locale?: string;
  disableClearable?: boolean;
  multiple?: boolean;
  labelPlacement?: LabelPlacement;
  creatable?: boolean;
  alternativeNames?: string[];
  useGroupBy?: boolean;
  asset?: Asset | null;
  limit?: number;
  onShowMoreClick?: () => void;
};

export type FormInput = FormInputBlueprint & {
  isValidatable?: boolean;
  validationErrors?: Array<string>;
};

export type FormSubmitInput = {
  [key: string]: string;
};

export type FormBehavior = {
  submitOnChange?: boolean;
};

export const useForm = <T>(
  inputBlueprints: Array<FormInputBlueprint>,
  onFormSubmit?: (inputs: T) => void,
  formBehavior?: FormBehavior,
) => {
  const [inputs, setInputs] = useState<Array<FormInput>>(inputBlueprints);
  const intl = useIntl();

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setInputs((prevState) => {
      const inputs = prevState.map((prevInput) => {
        return prevInput.name === event.target.name
          ? {
              ...prevInput,
              value: prevInput.capitalize
                ? event.target.value.toUpperCase()
                : event.target.value,
              validationErrors: prevInput.isValidatable
                ? getValidationError(
                    event.target.value,
                    prevInput.validation,
                    intl,
                  )
                : [],
            }
          : { ...prevInput };
      });

      formBehavior?.submitOnChange &&
        onFormSubmit &&
        onFormSubmit(getSubmitInputs(inputs));

      return inputs;
    });
  };

  const onCheckboxChange = (name: string, value: string) => {
    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              value: value,
              validationErrors: prevInput.isValidatable
                ? getValidationError(value, prevInput.validation, intl)
                : [],
            }
          : { ...prevInput },
      ),
    );
  };

  const onTimeChange = (name: string, value: string) => {
    setInputs((prevState) => {
      const inputs = prevState.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              value: value,
              validationErrors: prevInput.isValidatable
                ? getValidationError(value, prevInput.validation, intl)
                : [],
            }
          : { ...prevInput },
      );

      formBehavior?.submitOnChange &&
        onFormSubmit &&
        onFormSubmit(getSubmitInputs(inputs));

      return inputs;
    });
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const file = event.target.files ? event.target.files[0] : null;

    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === event.target.name
          ? {
              ...prevInput,
              value: file,
              isValidatable: true,
              validationErrors: file
                ? getValidationError(file, prevInput.validation, intl)
                : [],
            }
          : { ...prevInput },
      ),
    );
  };

  const onSelectChange = (value: string | string[] | any[], name: string) => {
    setInputs((prevState) => {
      const inputs = prevState.map((prevInput) => {
        return prevInput.name === name
          ? {
              ...prevInput,
              value: value,
              validationErrors: prevInput.isValidatable
                ? getValidationError(value, prevInput.validation, intl)
                : [],
            }
          : { ...prevInput };
      });

      formBehavior?.submitOnChange &&
        onFormSubmit &&
        onFormSubmit(getSubmitInputs(inputs));

      return inputs;
    });
  };

  const onClearInput = (name: string) => {
    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              value: '',
              validationErrors: prevInput.isValidatable
                ? getValidationError('', prevInput.validation, intl)
                : [],
            }
          : { ...prevInput },
      ),
    );
  };

  const onLoseInputFocus = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === event.target.name
          ? {
              ...prevInput,
              isValidatable: true,
              validationErrors: getValidationError(
                prevInput.value instanceof File
                  ? ''
                  : (IGNORED_TRIM.includes(prevInput.type)
                      ? prevInput.value
                      : trim(prevInput.value?.toString())) || '',
                prevInput.validation,
                intl,
              ),
            }
          : { ...prevInput },
      ),
    );
  };

  const setNewInputObject = (name: string, newValue: any) => {
    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              ...newValue,
            }
          : { ...prevInput },
      ),
    );
  };

  const onSubmit = (event?: ChangeEvent) => {
    event?.preventDefault();

    setInputs(
      inputs.map((input) => ({
        ...input,
        validationErrors: [],
      })),
    );

    const validatedInputs = inputs.map((input) => ({
      ...input,
      validationErrors: getValidationError(
        input.value instanceof File
          ? input.value
          : (IGNORED_TRIM.includes(input.type)
              ? input.value
              : trim(input.value?.toString())) || '',
        input.validation,
        intl,
      ),
    }));

    if (
      _.find(
        validatedInputs,
        (validatedInput) => !_.isEmpty(validatedInput.validationErrors),
      )
    ) {
      setInputs(validatedInputs);
      return;
    }

    onFormSubmit &&
      onFormSubmit(
        Object.assign(
          {},
          ...inputs.map((input) => ({
            [input.name]: IGNORED_TRIM.includes(input.type)
              ? input.value
              : trim(input.value?.toString()),
          })),
        ),
      );
  };

  const onFileDelete = (name: string, file: string | File) => {
    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              value:
                prevInput.multiple && Array.isArray(prevInput.value)
                  ? prevInput.value.filter((val: string | File) => {
                      if (file instanceof File && val instanceof File) {
                        return file.name !== val.name;
                      }

                      return val !== file;
                    })
                  : '',
              validationErrors: prevInput.isValidatable
                ? getValidationError('', prevInput.validation, intl)
                : [],
            }
          : { ...prevInput },
      ),
    );
  };

  const getSubmitInputs = (submitInputs: Array<FormInput>) =>
    Object.assign(
      {},
      ...submitInputs.map((input) => ({
        [input.name]:
          IGNORED_TRIM.includes(input.type) || Array.isArray(input.value)
            ? input.value
            : trim(input.value?.toString()),
      })),
    );

  const onSetValidationErrors = (error: HttpError) => {
    if (_.isArray(error)) {
      error.forEach((fieldError) => {
        setInputs((prevState) =>
          prevState.map((prevInput) =>
            prevInput.name === fieldError.field ||
            prevInput.alternativeNames?.includes(fieldError.field)
              ? {
                  ...prevInput,
                  isValidatable: true,
                  validationErrors: [fieldError.message],
                }
              : { ...prevInput },
          ),
        );
      });
    }
  };

  return {
    inputs,
    onInputChange,
    onCheckboxChange,
    onTimeChange,
    onFileChange,
    onLoseInputFocus,
    onSubmit,
    onClearInput,
    setNewInputObject,
    onSelectChange,
    onFileDelete,
    getSubmitInputs,
    onSetValidationErrors,
  };
};
