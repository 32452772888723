import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Document } from '../../domain/Document';

export const fetchDocumentStart = () => ({
  type: actionTypes.FETCH_DOCUMENT_START,
});

export const fetchDocumentSuccess = (document: Document) => ({
  type: actionTypes.FETCH_DOCUMENT_SUCCESS,
  document,
});

export const fetchDocumentFail = (document: HttpError) => ({
  type: actionTypes.FETCH_DOCUMENT_FAIL,
  document,
});

export const createDocumentStart = () => ({
  type: actionTypes.CREATE_DOCUMENT_START,
});

export const createDocumentSuccess = (createdDocument: Document) => ({
  type: actionTypes.CREATE_DOCUMENT_SUCCESS,
  createdDocument,
});

export const createDocumentFail = (documentCreateError: HttpError) => ({
  type: actionTypes.CREATE_DOCUMENT_FAIL,
  documentCreateError,
});

export const updateDocumentStart = () => ({
  type: actionTypes.UPDATE_DOCUMENT_START,
});

export const updateDocumentSuccess = () => ({
  type: actionTypes.UPDATE_DOCUMENT_SUCCESS,
});

export const updateDocumentFail = (documentUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_DOCUMENT_FAIL,
  documentUpdateError,
});

export const resetDocumentCreateState = () => ({
  type: actionTypes.RESET_DOCUMENT_STORE,
});

export const resetDocumentStore = () => ({
  type: actionTypes.RESET_DOCUMENT_STORE,
});
